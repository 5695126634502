import React from 'react'
import './Style.css';
import { Link } from 'react-router-dom';
import sign3 from '../assets/l1.png'
import sign4 from '../assets/l2.png'
import sign5 from '../assets/e-com.png'
import sign6 from '../assets/l4.png'
import sign7 from '../assets/l5.png'
import sign8 from '../assets/l6.png'
import sign9 from '../assets/l7.png'
import legal from '../assets/lgbg.png'
import Footer from './Footer';
import logo from '../assets/logo.png';
import { Helmet } from 'react-helmet-async';

const LegalConsulting = () => {
  return (
    <>
      <Helmet>
        <title>Legal Consulting Services in Dubai | True View</title>
        <meta name="description" content="True View provides trusted legal consulting services in Dubai. Our team of legal experts offers strategic advice to protect your business and ensure compliance." />
        <link rel="canonical" href="https://trueview.ae/legalconsulting" />
      </Helmet>
      <div className='legal'>
        <Link to='/'><img className='tv-logo' href src={logo} alt='logo'></img></Link>
        <img className='le-bg' src={legal} alt='leg'></img>
        <h1>Legal Consulting</h1><br /><br /><br /><br /><br /><br />

        <div className='le1'>
          <h3>Legal Consulting services</h3>
          <p>Our experienced legal team at True View provides comprehensive legal consulting services tailored to meet your specific needs.</p>
          <p>Whether you require advice on corporate law, contracts, or dispute resolution, we are here to guide you through the legal landscape with expertise and precision.</p>
        </div>

        <div className='row' id='le2'>
          <div className='column' id='vox'>
            <img src={sign3} alt='Conf' id='lgimg'></img>
          </div>
          <div className='column' id='vox'>
            <h4 id='lopki'><Link className='linkz' to='/counsel'>External General Counsel</Link></h4>
            <p id='lopki'>At True View, we understand that not every business has the resources or need for a full-time, in-house legal team. Our External General Counsel services provide you with the expertise and support of an experienced legal team on an as-needed basis. This flexible solution is ideal for businesses seeking high-quality legal advice and representation without the overhead costs associated with maintaining an in-house counsel...
              {/* <span ><Link to='/counsel'>learn more</Link></span> */}
            </p>
          </div>
        </div>

        <div className='row' id='le2'>
          <div className='column' id='vox'>
            <h4 id='lopki'><Link className='linkz' to='/corporate&commerical'>Corporate and Commercial Services</Link></h4>
            <p id='lopki'>At True View, our Corporate and Commercial Services are designed to support businesses at every stage of their growth. We provide comprehensive legal solutions to help your company navigate the complexities of the corporate world with confidence and ease. Our expert team is dedicated to delivering tailored advice and practical solutions that align with your business objectives...
              {/* <span ><Link  to='/corporate&commerical'>learn more</Link></span> */}
            </p>
          </div>
          <div className='column' id='vox'>
            <img src={sign4} alt='Conf' id='lgimg'></img>
          </div>
        </div>

        <div className='row' id='le2'>
          <div className='column' id='vox'>
            <img src={sign5} alt='Conf' id='lgimg'></img>
          </div>
          <div className='column' id='vox'>
            <h4 id='lopki'><Link className='linkz' to='/ecommerce'>E-Commerce</Link></h4>
            <p id='lopki'>In the dynamic world of e-commerce, True View offers specialized legal services to help you build a strong and legally compliant online presence. Our comprehensive e-commerce solutions are designed to address the unique challenges of digital business, ensuring your operations are secure, compliant, and successful...
              {/* <span ><Link  to='/ecommerce'>learn more</Link></span> */}
            </p>
          </div>
        </div>

        <div className='row' id='le2'>
          <div className='column' id='vox'>
            <h4 id='lopki'><Link className='linkz' to='/labourlaw'>Labour Law</Link></h4>
            <p id='lopki'>Navigating the complexities of labour law is essential for maintaining a harmonious and legally compliant workplace. At True View, we offer a comprehensive range of labour law services tailored to support both employees and employers. Our team of legal experts provides practical solutions to manage workplace relations and ensure compliance with the latest regulations...
              {/* <span ><Link  to='/labourlaw'>learn more</Link></span> */}
            </p>
          </div>
          <div className='column' id='vox'>
            <img src={sign6} alt='Conf' id='lgimg'></img>
          </div>
        </div>

        <div className='row' id='le2'>

          <div className='column' id='vox'>
            <img src={sign7} alt='Conf' id='lgimg'></img>
          </div>
          <div className='column' id='vox'>
            <h4 id='lopki'><Link className='linkz' to='/disputeresolution'>Dispute Resolution</Link></h4>
            <p id='lopki'>At True View, we understand that disputes can disrupt your business operations and impact your bottom line. Our Dispute Resolution Services are designed to provide effective and efficient solutions to resolve conflicts, recover debts, and protect your interests. Our experienced legal team is dedicated to guiding you through various dispute resolution processes with professionalism and expertise...
              {/* <span ><Link  to='/disputeresolution'>learn more</Link></span> */}
            </p>
          </div>
        </div>

        <div className='row' id='le2'>
          <div className='column' id='vox'>
            <h4 id='lopki'><Link className='linkz' to='/realestate'>Real Estate</Link></h4>
            <p id='lopki'>True View offers comprehensive legal services in the real estate sector, providing expert guidance and support for all types of property transactions and disputes. Our experienced team is dedicated to protecting your interests and ensuring that your real estate dealings are secure, compliant, and successful...
              {/* <span ><Link  to='/realestate'>learn more</Link></span> */}
            </p>
          </div>
          <div className='column' id='vox'>
            <img src={sign8} alt='Conf' id='lgimg'></img>
          </div>
        </div>

        <div className='row' id='le2' style={{ paddingBottom: '200px' }}>
          <div className='column' id='vox'>
            <img src={sign9} alt='Conf' id='lgimg'></img>
          </div>
          <div className='column' id='vox'>
            <h4 id='lopki'><Link className='linkz' to='/cryptolegal'>Crypto Legal Services</Link></h4>
            <p id='lopki'>In the rapidly evolving world of cryptocurrency and blockchain technology, True View offers specialized legal services to help you navigate this complex and highly regulated landscape. Our Crypto Legal Services are designed to provide comprehensive legal support, ensuring that your operations are compliant and strategically sound...
              {/* <span ><Link  to='/cryptolegal'>learn more</Link></span> */}
            </p>
          </div>
        </div>

        <div className='le3'>
          <h4>"The intersection fo Law, Policities and Technology is going to force a lot of good thinking."</h4>
          <p>Bill Gates</p>
        </div>
        <Footer />
      </div>
    </>
  )
}

export default LegalConsulting