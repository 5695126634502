import React from 'react'
import './Style.css';
import sign1 from '../assets/14.png'
import sign2 from '../assets/15.png'
import sign3 from '../assets/16.png'
import sign4 from '../assets/17.png'
import sign5 from '../assets/18.png'
import sign6 from '../assets/19.png'
import finance from '../assets/finan.png'
import Footer from './Footer';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png';
import { Helmet } from 'react-helmet-async';

function FinancialConsulting() {
  return (
    <>
      <Helmet>
        <title>Financial Consulting Services in Dubai | True View</title>
        <meta name="description" content="Looking for financial consulting in Dubai? True View delivers expert advice and financial strategies, helping you optimize your business growth and sustainability" />
        <link rel="canonical" href="https://trueview.ae/financialconsulting" />
      </Helmet>
      <div className='legal'>
        <Link to='/'><img className='tv-logo' href src={logo} alt='logo'></img></Link>
        <img className='le-bg' src={finance} alt='leg'></img>
        <h1>Financial Consulting</h1><br /><br /><br /><br /><br /><br />

        <div className='le1'>
          <h3>Financial Consulting Services</h3>
          <p>We understand the complexities of financial matters in today's business environment.</p>
          <p>Our financial consulting services are designed to help you make informed decisions, optimize your financial performance, and achieve your business goals.</p>
        </div>

        <div className='row' id='le2'>
          <div className='column' id='vox'>
            <img src={sign1} alt='Conf' id='lgimg'></img>

          </div>
          <div className='column' id='vox'>
            <h4 id='lopki'><Link className='linkz' to='/'>Estate Planning</Link></h4>
            <p id='lopki'>This service entails developing a comprehensive strategy for the distribution of assets after one's passing. Financial consultants play a crucial role in estate planning by advising clients on minimizing tax obligations and maximizing inheritances.
              {/* <span ><Link className='linkz' to='/estateplan'>learn more</Link></span> */}
            </p>
          </div>
        </div>

        <div className='row' id='le2'>
          <div className='column' id='vox'>
            <h4 id='lopki'><Link className='linkz' to='/'>Retirement Planning</Link></h4>
            <p id='lopki'>A financial consultant can assist individuals in preparing for a secure retirement by determining the amount of savings required to cover their living expenses. They provide projections and guidance to ensure a comfortable and financially stable future.
              {/* <span ><Link className='linkz' to='/retairplan'>learn more</Link></span> */}
            </p>
          </div>
          <div className='column' id='vox'>
            <img src={sign2} alt='Conf' id='lgimg'></img>

          </div>
        </div>

        <div className='row' id='le2'>
          <div className='column' id='vox'>
            <img src={sign3} alt='Conf' id='lgimg'></img>

          </div>
          <div className='column' id='vox'>
            <h4 id='lopki'><Link className='linkz' to='/'>Investment Planning</Link></h4>
            <p id='lopki'>When it comes to investment planning, financial consultants assess a client's risk tolerance and financial goals to determine the most suitable investment options. They often suggest a diversified portfolio of stocks, bonds, and mutual funds to help clients achieve long-term wealth growth.
              {/* <span ><Link className='linkz' to='/investplan'>learn more</Link></span> */}
            </p>
          </div>
        </div>

        <div className='row' id='le2'>
          <div className='column' id='vox'>
            <h4 id='lopki'><Link className='linkz' to='/'>Insurance Planning</Link></h4>
            <p id='lopki'>Having insurance planning in place is crucial for safeguarding assets and income in case of unforeseen circumstances like death or illness. Financial consultants play a vital role in assisting clients in selecting the appropriate insurance type and coverage that best aligns with their specific needs.
              {/* <span ><Link className='linkz' to='/insuranceplan'>learn more</Link></span> */}
            </p>
          </div>
          <div className='column' id='vox'>
            <img src={sign4} alt='Conf' id='lgimg'></img>

          </div>
        </div>

        <div className='row' id='le2'>
          <div className='column' id='vox'>
            <img src={sign5} alt='Conf' id='lgimg'></img>

          </div>
          <div className='column' id='vox'>
            <h4 id='lopki'><Link className='linkz' to='/'>Tax Planning</Link></h4>
            <p id='lopki'>A financial consultant can offer valuable strategies to reduce a client's tax burden. These strategies may include leveraging tax deductions or exploring specific types of investment accounts.
              {/* <span ><Link className='linkz' to='/taxplan'>learn more</Link></span> */}
            </p>
          </div>
        </div>

        <div className='row' id='le2' style={{ paddingBottom: '200px' }}>
          <div className='column' id='vox'>
            <h4 id='lopki'><Link className='linkz' to='/'>Business and Financial Strategy</Link></h4>
            <p id='lopki'>A financial consultant can assist businesses in enhancing their profitability and cash flow by devising and executing strategies. These strategies may entail adjustments to pricing, marketing approaches, or operational processes.
              {/* <span ><Link className='linkz' to='/businessandfinance'>learn more</Link></span> */}
            </p>
          </div>
          <div className='column' id='vox'>
            <img src={sign6} alt='Conf' id='lgimg'></img>

          </div>
        </div>

        <div className='le3'>
          <h4>"An investment in knowledge pays the best interest."</h4>
          <p>Benjamin Franklin</p>
        </div>
        <Footer />
      </div>
    </>
  )
}

export default FinancialConsulting