import React from 'react'
import './Style.css';
import { Link } from 'react-router-dom';
import sign3 from '../assets/b1.png'
import sign4 from '../assets/b2.png'
import sign5 from '../assets/b3.png'
import sign6 from '../assets/b4.png'
import sign7 from '../assets/b5.png'
import sign8 from '../assets/b6.png'
import legal from '../assets/bspic.png'
import Footer from './Footer';
import logo from '../assets/logo.png';
import { Helmet } from 'react-helmet-async';

const BusinessSetup = () => {
  return (
    <>
      <Helmet>
        <title>Business Setup Services in Dubai | True View Expert Consultants</title>
        <meta name="description" content="Start your business in Dubai with ease. True View offers full business setup services, from legal paperwork to market entry, ensuring a smooth start to your company." />
        <link rel="canonical" href="https://trueview.ae/businesssetup" />
      </Helmet>
      <div className='legal'>
        <Link to='/'><img className='tv-logo' href src={logo} alt='logo'></img></Link>
        <img className='le-bg' src={legal} alt='leg'></img>
        <h1>Business SetUp</h1><br /><br /><br /><br /><br /><br />

        <div className='le1'>
          <h3>Business SetUp Services</h3>
          <p>We provide professional business setup services encompassing company incorporation, license renewals, share transfers, and much more. Benefit from our expertise to navigate the intricacies of business setup effectively. Reach out to commence your business journey today.</p>
        </div>

        <div className='row' id='le2'>
          <div className='column' id='vox'>
            <img src={sign3} alt='Conf' id='lgimg'></img>
          </div>
          <div className='column' id='vox'>
            <h4 id='lopki'><Link className='linkz' to='/'>FREE ZONE & OFFSHORE COMPANY REGISTRATION</Link></h4>
            <p id='lopki'>We have vast expertise in forming various free zone and offshore businesses in the UAE and internationally. Our range of services includes setting up companies in free zones and offshore locations, providing registered agent services, handling license renewals and amendments, managing share transfers, appointing and removing officers, drafting and revising memorandum and articles of association, as well as assisting with liquidation and de-registration processes.
              {/* <span ><Link className='linkz' to='/fzgcc'>learn more</Link></span> */}
            </p>
          </div>
        </div>

        <div className='row' id='le2'>
          <div className='column' id='vox'>
            <h4 id='lopki'><Link className='linkz' to='/'>UAE ONSHORE COMPANY REGISTRATION</Link></h4>
            <p id='lopki'>We handle setting up various legal entities in the UAE comprehensively. Our UAE company registration service covers forming different company types, managing licenses, share transfers, officer appointments, drafting legal documents, and assisting with liquidation processes. Moreover, addressing the Economic Substance Regulations (ESR) in the UAE is crucial.
              {/* <span ><Link className='linkz' to='/onshore'>learn more</Link></span> */}
            </p>
          </div>
          <div className='column' id='vox'>
            <img src={sign4} alt='Conf' id='lgimg'></img>
          </div>
        </div>

        <div className='row' id='le2'>
          <div className='column' id='vox'>
            <img src={sign5} alt='Conf' id='lgimg'></img>
          </div>
          <div className='column' id='vox'>
            <h4 id='lopki'><Link className='linkz' to='/'>DOCUMENT LEGALISATION</Link></h4>
            <p id='lopki'>We can support you with various legal document services, such as document certification, legalizing documents for use in the UAE, authenticating documents at the UAE Ministry of Foreign Affairs and UAE Ministry of Justice, notarizing documents, assisting with document legalization at consulates and embassies, handling Powers of Attorney, and ensuring companies comply with the law. Also, we've summarized the key rules to guide you through the complexities of the ESR and covered important aspects to aid in achieving compliance.
              {/* <span ><Link className='linkz' to='/doclegal'>learn more</Link></span> */}
            </p>
          </div>
        </div>

        <div className='row' id='le2'>
          <div className='column' id='vox'>
            <h4 id='lopki'><Link className='linkz' to='/'>PRO SERVICES</Link></h4>
            <p id='lopki'>We've built strong and effective connections with key government officials in the UAE, ensuring precise and prompt service delivery. Our support includes handling business administration, licensing matters, product approvals, Ministry of Labour and Immigration procedures, work permits, visa applications, medical checks, Emirates ID assistance, visa cancellations, criminal and police liaison services, as well as employee visas and immigration affairs.
              {/* <span ><Link className='linkz' to='/proservice'>learn more</Link></span> */}
            </p>
          </div>
          <div className='column' id='vox'>
            <img src={sign6} alt='Conf' id='lgimg'></img>
          </div>
        </div>

        <div className='row' id='le2'>

          <div className='column' id='vox'>
            <img src={sign7} alt='Conf' id='lgimg'></img>
          </div>
          <div className='column' id='vox'>
            <h4 id='lopki'><Link className='linkz' to='/'>BUSINESS SPONSORSHIP</Link></h4>
            <p id='lopki'>We're here to offer sponsor local service agent services for UAE and GCC entities, covering activities like setting up investment vehicles, establishing companies, serving as a registered agent for RAKICC (Ras Al Khaimah International Corporate Centre), handling licensing, general administration, and conducting negotiations on behalf of clients.
              {/* <span ><Link className='linkz' to='/bussinessponser'>learn more</Link></span> */}
            </p>
          </div>
        </div>

        <div className='row' id='le2' style={{ paddingBottom: '200px' }}>
          <div className='column' id='vox'>
            <h4 id='lopki'><Link className='linkz' to='/'>FREEZONE, OFFSHORE & GCC COMPANY REGISTRATION</Link></h4>
            <p id='lopki'>We can assist with various services such as Free Zone and Offshore company incorporation in the GCC and abroad, providing registered agent services for RAKICC and ADGM, handling license renewals, amendments, share transfers, appointment and removal of officers, drafting and amending memorandum and articles of association, as well as liquidation and de-registration. Let me know if you need more information on these company setup and management services!
              {/* <span ><Link className='linkz' to='/fzofshore'>learn more</Link></span> */}
            </p>
          </div>
          <div className='column' id='vox'>
            <img src={sign8} alt='Conf' id='lgimg'></img>
          </div>
        </div>

        <div className='le3'>
          <h4>"The intersection fo Law, Policities and Technology is going to force a lot of good thinking."</h4>
          <p>Bill Gates</p>
        </div>
        <Footer />
      </div>
    </>
  )
}

export default BusinessSetup;