import React from 'react'
import { Link } from 'react-router-dom';
import videoTV from '../assets/tvbg.mp4';
import './Style.css';
import lgImg from '../assets/legalh.PNG';
import conImg from '../assets/finanh.PNG';
import busImg from '../assets/bussh.PNG'
import logo from '../assets/logo.png';
import Footer from './Footer';
import { Helmet } from 'react-helmet-async';


const Home = () => {
    return (
        <>
            <Helmet>
                <title>Legal & Financial Consulting in Dubai | True View </title>
                <meta name="description"
                    content="Need expert legal and financial consulting in Dubai? True View offers custom solutions, helping businesses thrive with our strategic guidance and professional services." />

                <link rel="canonical" href="https://trueview.ae/" />
            </Helmet>
            <div className='whole-slide'>
                <Link to='/'> <img className='tv-logo' href src={logo} alt='logo'></img></Link>
                <video className="video-bg" autoPlay loop muted>
                    <source src={videoTV} type="video/mp4" />
                </video>
                <div className='slide4' style={{marginTop:'340px'}}>
                    <h4>Who We Are</h4>
                    <p>At True View, we pride ourselves on our commitment to excellence, integrity, and personalized service.</p>
                    <p>Our team of experienced professionals is dedicated to providing tailored solutions to meet your legal and financial needs effectively.</p>
                    <p>With a deep understanding of the local laws and regulations, we offer expert guidance on a wide range of legal and financial matters. Whether you are a business looking for strategic advice or an individual seeking legal assistance.</p>
                    <p id='silde-btm'>Our core values of transparency, professionalism, and client satisfaction drive everything we do. We believe in building long-lasting relationships with our clients based on trust and mutual respect.</p>
                </div>
                <div className='slide3'>
                    <h5 id='quote'>"Your success will be determined by your own confidence and fortitude."</h5>
                    <p>Michelle Obama</p>
                </div>
                <div className='slide2'>
                    <h4>Our Services</h4>
                    <p>We provide top-notch legal consulting, financial consulting, and business setup services to ensure businesses have a solid foundation for success.</p>
                    <p>Our legal consulting team offers guidance on corporate law, contracts, and dispute resolution, tailored to meet specific needs.</p>
                    <p>In the financial consulting realm, we help businesses optimize performance through financial planning and risk management.</p>
                    <p id='silde-btm'>Additionally, our business setup services streamline the process for entrepreneurs looking to start or expand their ventures.</p>

                    <button style={{ backgroundColor: '#E7D49E', border: 'none' }}><Link style={{ textDecoration: 'none', color: 'black', fontSize: '26px' }} to='/team'>Our Team</Link></button>

                    <div className='row' id='ot-box'>
                        <div className='column' id='is-box'>
                            <Link to='/legalconsulting'>
                                <img src={lgImg} className='home-img' alt='Legal Consulting' />
                                <span>Legal Consulting</span>
                            </Link>
                        </div>
                        <div className='column' id='is-box'>
                            <Link to='/financialconsulting'>
                                <img src={conImg} className='home-img' alt='Financial Consulting' />
                                <span>Financial Consulting</span>
                            </Link>
                        </div>
                        <div className='column' id='is-box'>
                            <Link to='/businesssetup'>
                                <img src={busImg} className='home-img' alt='Business Setup' />
                                <span>Business Setup</span>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className='slide3'>
                    <h5 id='quote'>"Your work is going to fill a large part of your life, and the only way to be truly satisfied is to do what you believe is great work."</h5>
                    <p>Steve Jobs</p>
                </div>
                <div className='slide4'>

                    <div className='row' id='rew'>
                        <div id='box-left' className='column'>
                            <h2>Why</h2>
                            <h2>Choose</h2>
                            <h2>Us</h2>
                        </div>
                        <div id='box-right' className='column'>
                            <p>Expert Guidance Provided</p>
                            <p>Tailored Solutions Offered</p>
                            <p>Client-Centric Approach Emphasized</p>
                            <p>Transparent Services Ensured</p>
                            <p>Professional Team Dedicated</p>
                            <p>Excellence Guaranteed Always</p>
                            <p>Trusted Partner Ensured</p>
                        </div>
                    </div>

                </div>
                <div className='slide3'>
                    <h5 id='quote'>"Ethics is knowing the difference between what you have a right to do and what is right to do."</h5>
                    <p>Potter Stewart</p>
                </div>
                <div className='slide5'>
                    <div className='container'>
                        <div className='row'>
                            <div className='column' id='insta-col1'>
                                <Link to='/'> <img className='insta-logo' href src={logo} alt='logo'></img></Link>
                            </div>
                            <div className='column' id='insta-col2'>
                                <h5>True View</h5>
                                <p>LEGAL & CONSULTING</p>
                            </div>
                            <div className='insta-box'>
                                <h2>posts</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='slide3'>
                    <h5 id='quote'>“Some people dream of success, while other people get up every morning and make it happen.”</h5>
                    <p>Wayne Huizenga</p>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default Home